/**
 * Dashkit v1.4.2
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */

// Bootstrap functions
@import '../../../node_modules/bootstrap/scss/functions';

// Custom variables
@import 'user-variables';

// Dashkit variables
@import 'variables';

// Bootstrap core
@import '../../../node_modules/bootstrap/scss/bootstrap';

// Dashkit core
@import '../../theme/Dashkit/Dashkit-1.6.0/src/assets/scss/dashkit/dashkit';

@import "custom";

.navbar-light {
    background-color: #FFFFFF;
    border-color: #E3EBF6;
}

// Navbar navigaton

.navbar-nav .nav-link {

    &.active {

        &:before {
            border-left: 2px solid $green !important;
        }
    }
}

.modal {

    .fixed-right
    {
        .model-body img.card-img-top
        {
            border-radius:0;
        }
    }

}
